<template>
  <div class="news-for">
    <div class="news-for-content">
      <Collapse title="Noticia privada" ref="Collapse" v-bind:settings="collapseSettings" class="list" @open="listOpen">
        <template v-slot:header>
          <div class="news-for-header" w100>
            <div class="news-for-title" w100>
              <span class="news-for-label sec-font" @click="$refs['Collapse'].toggle()" pointer w100>
                <iconic class="icon_user" name="user" />
                <span>Noticia privada para</span>
              </span>
            </div>
            <div class="news-for-selected" v-if="userSelected.length >= 1">
              <div class="news-for-selected-title">Usuarios seleccionados</div>
              <NewsForUserItem
                v-for="(user, index) in userSelected"
                :key="`s` + index + user._id"
                :user="user"
                :isSelected="true"
                :delete="selectUser"
                :last="lastSelect"
                list="selected"
              />
            </div>
          </div>
        </template>
        <div class="news-for-users">
          <div class="news-for-search">
            <div class="news-for-users-title">Buscar usuarios</div>
            <div class="news-for-users-search">
              <input type="text" name="q" placeholder="Buscar usuario" autocomplete="off" class="newNews__inputs" @keyup="onKeyUp" ref="txt" />
              <iconic :name="loading ? 'gspinner' : 'search'" />
            </div>
          </div>
          <div class="news-for-users-result">
            <NewsForUserItem
              v-for="(user, index) in users"
              :key="index + user._id"
              :user="user"
              @click.native="selectUser(user)"
              :last="lastSelect"
              list="result"
            />
          </div>
        </div>
      </Collapse>
    </div>
  </div>
</template>

<script>
export default {
  components: { Collapse: () => import("@/components/widgets/Collapse.vue"), NewsForUserItem: () => import("./NewsForUserItem.vue") },
  props: ["currentUsers"],
  data: function() {
    return {
      users: [],
      loading: false,
      requesing: null,
      tovisible: null,
      keyDelay: 500,
      userSelected: [],
      lastSelect: null,
      collapseSettings: {
        scrollIntoView: true,
        openHeader: false,
      },
    };
  },
  methods: {
    listOpen: function() {
      const searchBox = this.$refs.txt;
      searchBox.focus();
    },
    isUser: function(user) {
      if (user) {
        return this.userSelected.find(({ _id }) => user._id == _id);
      }
    },
    updateResult: function(user, status) {
      if (status && user) {
        this.users.push(user);
      } else {
        this.users = this.users.filter(({ _id }) => user._id != _id);
      }
    },
    selectUser: function(user) {
      if (!this.isUser(user)) {
        this.userSelected.push(user);
        this.updateResult(user, false);
        const searchBox = this.$refs.txt;
        searchBox.value = "";
      } else {
        this.lastSelect = null;
        this.userSelected = this.userSelected.filter(({ _id }) => user._id != _id);
        this.updateResult(user, true);
      }
      this.users = [];
      this.lastSelect = user._id;
      const usersIDsList = this.userSelected.map(({ _id }) => _id);
      this.$emit("select", usersIDsList);
    },
    removeAlreadySelected: function(users) {
      return users.filter(({ _id }) => !this.isUser({ _id }));
    },
    searchUser: async function({ target: { value }, page }) {
      this.loading = true;
      const collapse = this.$refs.Collapse.$el;
      if (!value) {
        this.users = [];
        this.loading = false;
        return;
      }
      try {
        this.$store.state.monitors = "loading";
        const host = `users?role=coordinator&limit=5&page=0&q=${value}`;
        const { data } = await this.$api.get(`${host}`);
        this.toView({ target: collapse });
        this.users = this.removeAlreadySelected(data.users);
      } catch (error) {
        this.$Swal.fire({ title: "Error", text: error });
      }
      this.loading = false;
    },
    toView: function({ target }) {
      if (this.users.length > 0) return;
      clearTimeout(this.tovisible);
      this.tovisible = setTimeout(() => {
        target && target.scrollIntoView({ behavior: "smooth" });
      }, 100);
    },
    onKeyUp: function($event) {
      this.loading = true;
      clearTimeout(this.requesing);
      this.requesing = setTimeout(() => {
        this.searchUser($event);
      }, this.keyDelay);
    },
  },
  mounted: function() {
    this.userSelected = this.currentUsers && this.currentUsers.length > 0 ? this.currentUsers : [];
  },
};
</script>

<style lang="scss">
.news-for {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: $mpadding/2 0 0 0;
  * {
    user-select: none;
  }
  &-title {
    @include Flex(inherit, flex-start, center);
    font-size: 120%;
    border-bottom: solid 1px $alto;
    .button {
      @include Flex(inherit, center, center);
      margin: 0 $mpadding;
      &.delete {
        color: $primary_color;
        .iconic {
          @include Flex(inherit, center, center);
          color: $primary_color;
          font-size: 90%;
        }
      }
    }
  }
  .collapse-header &-label {
    .icon_user.iconic {
      font-size: 120%;
    }
  }
  .collapse {
    display: block;
    &-header {
      .title {
        font-size: 1.2rem;
      }
      .iconic.right-icon {
        font-size: 120%;
      }
      .news-for-label,
      .right-icon {
        padding: $mpadding/2 0;
      }
    }
    &.list {
      .collapse-header {
        align-items: baseline;
        &-left {
          width: 100%;
          justify-content: flex-start;
        }
        &-right {
          margin-left: -$mpadding * 1.3;
        }
      }
    }
  }
  &-selected,
  &-users {
    margin: $mpadding 0 0 0;
    &-title {
      font-family: $sec_font;
    }
  }
  &-users {
    &-search {
      position: relative;
      margin: $mpadding/2 0;
      .iconic {
        position: absolute;
        left: $mpadding;
        top: 50%;
        transform: translateY(-50%);
        @include Flex(inherit, center, center);
      }
      input {
        width: 100%;
        padding: 0 $mpadding 0 $mpadding * 3;
      }
    }
    &-result {
      position: relative;
    }
  }
}
</style>
